import { createSlice } from '@reduxjs/toolkit';
import { randomHex } from '../../utility/Element';
const emptyIngredient = {
          "id": undefined,
          "label": undefined,
          "value": undefined
        };
let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let context = new URLSearchParams(window.location.search).get("detail");
const yyyy = today.getFullYear();

today = dd + '-' + mm + '-' + yyyy;
const initialState = {
  "recipe":{
    "date": today,
    "title": undefined,
    "image": [],
    "badges": [],
    "credits": undefined,
    "preparations": []
  },
  "ingredients": undefined,
  "context": context,
};

export const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTitle: (state, action) => {
      state.recipe.title = action.payload;
    },
    setContext: (state, action) => {

      state.context = action.payload.context;
    },
    addPreparation: (state, action) => {
      state.recipe.preparations.push( 
        {
          "id": undefined,
          "title": undefined,
          "tags": [],
          "ingredients": [],
          "description": undefined
      });
      if (action.payload.callback) {
        action.payload.callback(state.recipe.preparations.length - 1);
      }
    },
    removePreparation: (state, action) => {
      state.recipe.preparations.splice(action.payload, 1);
    },
    addBadge: (state, action) => {
      state.recipe.badges.push(action.payload);
    },
    removeBadge: (state, action) => {
      state.recipe.badges.splice(action.payload, 1);
    },
    copyPreparation: (state, action) => {
      state.recipe.preparations[action.payload.index] = {...action.payload.value}
    },
    setPreparationTitle: (state, action) => {
      state.recipe.preparations[action.payload.index].title = action.payload.value;
    },
    setPreparationDescription: (state, action) => {
      state.recipe.preparations[action.payload.index].description = action.payload.value;
    },
    setPreparationID: (state, action) => {
      state.recipe.preparations[action.payload.index].id = action.payload.value;
    },
    addTag: (state, action) => {
      const checkExist = state.recipe.preparations[action.payload.index].tags.some((element) => {
        return element.value.toLowerCase().trim() === action.payload.tag.value.toLowerCase().trim();
      })
      if (!checkExist) {
        state.recipe.preparations[action.payload.index].tags.push(action.payload.tag);
      }
    },
    removeTag: (state, action) => {
      state.recipe.preparations[action.payload.preparation].tags.splice(action.payload.tag, 1);
    },
    addIngredient: (state, action) => {
      state.recipe.preparations[action.payload.index].ingredients.push({...emptyIngredient});
      if (action.payload.callback) {
        action.payload.callback({
          preparation: action.payload.index,
          ingredient: state.recipe.preparations[action.payload.index].ingredients.length - 1
        });
      }

    },
    removeIngredient: (state, action) => {
      state.recipe.preparations[action.payload.preparation].ingredients.splice(action.payload.ingredient, 1);
    },
    copyIngredient: (state, action) => {
      const tempIngr = {...action.payload.value};
      tempIngr.value = undefined;
      state.recipe.preparations[action.payload.indexes.preparation]
        .ingredients[action.payload.indexes.ingredient] = tempIngr;
    },
    setIngredientValue: (state, action) => {
      state.recipe.preparations[action.payload.indexes.preparation]
        .ingredients[action.payload.indexes.ingredient].value = action.payload.value;
    },
    setIngredientLabel: (state, action) => {
      state.recipe.preparations[action.payload.indexes.preparation]
        .ingredients[action.payload.indexes.ingredient].label = action.payload.label;
      if (action.payload.label)
        state.recipe.preparations[action.payload.indexes.preparation]
          .ingredients[action.payload.indexes.ingredient].id = action.payload.label.replaceAll(' ', '_');
    },
  },
});

export const { 
  setContext,
  setTitle,
  addBadge,
  removeBadge,
  addPreparation,
  removePreparation,
  copyPreparation,
  setPreparationTitle,
  setPreparationID,
  setPreparationDescription,
  addTag,
  removeTag,
  removeIngredient,
  copyIngredient,
  addIngredient,
  setIngredientValue,
  setIngredientLabel
} = recipeSlice.actions;

export const getRecipe = (state) => state.recipe.recipe;
export const getContext = (state) => state.recipe.context;
export const getIngredients = (state) =>{
  if (ingredients.length === 0) {
    extractRecipeDetail();
  }
  return ingredients;
};

export const getMainIngredients = (state) =>{
  if (main.ingredients.length === 0) {
    extractRecipeDetail();
  }
  return main.ingredients;
};

export const getCredits = () =>{
  if (credits.length === 0) {
    extractRecipeDetail();
  }
  return credits;
};

export const getMainCredits = () =>{
  if (main.credits.length === 0) {
    extractRecipeDetail();
  }
  return main.credits;
};

export const getBadges = (state) =>{
  if (badges.length === 0) {
    extractRecipeDetail();
  }
  return badges;
};

export const getTags = (state) =>{
  if (tags.length === 0) {
    extractRecipeDetail();
  }
  return tags;
};

export const getMainTags = () =>{
  if (main.tags.length === 0) {
    extractRecipeDetail();
  }
  return main.tags;
};

export const getPreparations = (state) => {
  if (preparations.length === 0) {
    extractRecipeDetail();
  }
  return preparations;
};

export default recipeSlice.reducer;

let ingredients = [];
let credits = [];
let badges = [];
let tags = [];
let main = {
  credits: [],
  tags: [], 
  ingredients: []
}
const preparations = [];

const extractRecipeDetail=() => {
  const jsonModules= require.context('../../json/', true, /\.json$/);
  jsonModules.keys().forEach((key, i) => {
    if(jsonModules(key)["badges"]) {
      badges = badges.concat(jsonModules(key)["badges"].filter((badge)=> 
        filterSome(badge, badges)));
    }
    jsonModules(key).preparations.forEach((element, index) => {
      let optionElement = {label: element.title, value: element};
      const check = checkIndexByLabel(preparations, optionElement.label) ;
      if ( !check ) {
        preparations.push(optionElement);
      }

      if(element["ingredients"] && Array.isArray(element["ingredients"])) {
        ingredients = ingredients.concat(element["ingredients"].filter((ingredient)=>
          filterSome(ingredient, ingredients, "id")));
      }

      if(element["credits"] && filterSome({value: element["credits"]}, credits)) {
        credits.push({value: element["credits"]});
      }

      if(element["tags"] && Array.isArray(element["tags"])) {
        tags = tags.concat(element["tags"].filter((tag)=>
        filterSome(tag, tags)));
      }
    });
  });
  extractRecipeDetailForMain();
}

const extractRecipeDetailForMain = () => {
  const jsonModules= require.context('../../json/', false, /\.json$/);
  jsonModules.keys().forEach((key, i) => {
    jsonModules(key).preparations.forEach((element, index) => {

      if(element["ingredients"] && Array.isArray(element["ingredients"])) {
        main.ingredients = main.ingredients.concat(element["ingredients"].filter((ingr) => {
          return !main.ingredients.some((someEle) => { return someEle.value.id === ingr.id }) && (ingr.id !== '');
        }).map((ingr) => {
          return { label: ingr.label || ingr.id, value: ingr };
        })).sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
      }

      if(element["credits"] && filterSome({value: element["credits"]}, main.credits)) {
        main.credits.push({value: element["credits"]});
      }

      if(element["tags"] && Array.isArray(element["tags"])) {
        main.tags = main.tags.concat(element["tags"].filter((tag)=>
        filterTag(tag, main.tags)));
      }
    });
  });
}


const filterSome = (element, array, compareAttribute) => {
  compareAttribute = compareAttribute ? compareAttribute:"value";
  return !array.some((arrayElement) => element[compareAttribute] === arrayElement[compareAttribute] );
}

const filterTag = (tag, tags) => {
  if (tag) {
    tag.count = 0;
    tag.props = {
      style: {
        cursor: 'pointer',
        color: randomHex('black'),
      }
    }
  }
  return !tags.some((someEle) => {
    if (tag === undefined || tag.value === undefined) {
      return false;
    }
    if (someEle.value === tag.value) {
      if (someEle.count) {
        someEle.count = someEle.count + 1;
      } else {
        someEle.count = 1;
      }
      return true;
    }
    return false;
  });
}


const checkIndexByLabel = (elements, label) => {
  return elements.some(arg => {
    return arg.label === label
  });
}