import {h,innerH} from '../utility/Element.js'
const heroCSS = `
.polaroid{
  display: inline-block;
  height: 100%;
  width: 100%;
  font-family: 'Poor Story', cursive;
}
.positionSticky {
  position: -webkit-sticky;
  position: sticky;
}
.disableSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.textBackground {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.centerX {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.centerY {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.centerXY {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.displayFlex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.displayGrid {
  display: -ms-grid;
  display: grid;
}
.displayInlineGrid {
  display: inline-grid;
}
body {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
ul.cards {
  position: absolute;
  overflow: unset;
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: auto;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: transparent;
  z-index: 5;
}
ul.cards li {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 650px;
  max-height: 850px;
  padding: 15px 15px 150px;
  z-index: 8;
  opacity: 0;
  color: #101010;
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  line-height: 25px;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transform: translateX(-100%) rotate(3deg) scale(0.98);
  -ms-transform: translateX(-100%) rotate(3deg) scale(0.98);
  transform: translateX(-100%) rotate(3deg) scale(0.98);
  -webkit-transition: 0.75s all cubic-bezier(1, -0.5, 0.2, 1.4);
  transition: 0.75s all cubic-bezier(1, -0.5, 0.2, 1.4);
}
ul.cards li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 2px inset rgba(0, 0, 0, 0.6);
}
ul.cards li:not(.clean) {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
}
ul.cards li:not(.clean):before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
ul.cards li:not(.clean):after {
  content: attr(title);
  line-height: 0.8;
  position: absolute;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  height: 160px;
  bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
ul.cards li.clean {
  padding: 0 40px;
}

ul.cards li:nth-child(1){
  --nth-child: 0deg;
}
ul.cards li:nth-child(2){
  --nth-child: 10deg;
}
ul.cards li:nth-child(3){
  --nth-child: -10deg;
}
ul.cards li:nth-child(4){
  --nth-child: -20deg;
}
ul.cards li:nth-child(5){
  --nth-child: 13deg;
}
ul.cards li:nth-child(6){
  --nth-child: -13deg;
}
ul.cards li:nth-child(7){
  --nth-child: -6deg;
}
ul.cards li:nth-child(8){
  --nth-child: 6deg;
}
ul.cards li:nth-child(9){
  --nth-child: 8deg;
}
ul.cards li.current {
  z-index: 7;
  -webkit-transform: translateX(0) rotate(calc(0deg)) !important;
  -ms-transform: translateX(0) rotate(calc(0deg)) !important;
  transform: translateX(0) rotate(calc(0deg)) !important;
}
ul.cards li.current,
ul.cards li.current ~ li {
  opacity: 1;
  -webkit-transform: translateX(0) rotate(calc(0deg + var(--nth-child)));
  -ms-transform: translateX(0) rotate(calc(0deg + var(--nth-child)));
  transform: translateX(0) rotate(calc(0deg + var(--nth-child)));
}
ul.cards li.current + li {
  z-index: 6;
}
ul.cards li.current + li ~ li {
  z-index: 5;
}
ul.cards li i {
  font-style: normal;
  font-size: 96px;
}
button.arrow {
  position: absolute;
  height: 100%;
  border: 0;
  padding: 0 10px;
  background: none;
  font-family: 'Poor Story', cursive;
  color: #101010;
  font-weight: bolder;
  font-size: 25px;
  z-index: 8;
  cursor: pointer;
  outline: none;
}
button.arrow,
button.arrow:after,
button.arrow span {
  -webkit-transition: 0.25s all ease-out;
  transition: 0.25s all ease-out;
}
button.arrow:after {
  display: inline-block;
}
button.arrow.prev {
  left: 0;
}
button.arrow.prev:after {
  content: '< Prev';
  text-shadow: -4px 2px 5px rgba(250, 250, 250, 0.5);
  padding-left: 25px;
}
button.arrow.next {
  right: 0;
}
button.arrow.next:after {
  content: 'Next >';
  text-shadow: 4px 2px 5px rgba(250, 250, 250, 0.5);
}
button.arrow.disabled {
  opacity: 0.2;
  cursor: default;
}
button.arrow:hover:not(.disabled):before,
button.arrow:focus:not(.disabled):before {
  opacity: 1;
}
button.arrow:hover:not(.disabled):after,
button.arrow:focus:not(.disabled):after {
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
@media all and (max-width: 800px) {
  button.arrow {
    font-size: 3em;
  }
  button.arrow.prev:after {
    content: '<';
  }
  button.arrow.next:after {
    content: '>';
  }
}
`;
class ImageGallery extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    for (let index in this.cache) {
      getDefaultTemplate(index, this.cache[index]);
    }
    this.template = template(this);
    this.shadow.appendChild(getDefaultStyle(heroCSS));
    this.shadow.appendChild(this.template);
//  swipe event listner
    this.addEventListener('swiped-left', function(e) {
      moveCards(true);
    });
    this.addEventListener('swiped-right', function(e) {
      moveCards(false);
    });
    var SRoot = document.querySelector("raga-photo-gallery").shadowRoot;
    const prev = SRoot.querySelector(".arrow.prev");
    const next = SRoot.querySelector(".arrow.next");
    const moveCards = isNext => {
      let current = SRoot.querySelector(".current"),
        newCurrent = isNext
          ? current.nextElementSibling
          : current.previousElementSibling;

      if (newCurrent) {
        current.classList.remove("current");
        newCurrent.classList.add("current");

        if (isNext && newCurrent.nextElementSibling == null) {
          next.classList.add("disabled");
        } else if (!isNext && newCurrent.previousElementSibling == null) {
          prev.classList.add("disabled");
        } else {
          prev.classList.remove("disabled");
          next.classList.remove("disabled");
        }
      }
    };

    prev.onclick = () => moveCards(false);
    next.onclick = () => moveCards(true);
    prev.classList.add("disabled");
  }
}
function getDefaultStyle(css) {
  return h('style', null, css);
}
function template(self) {
  let image = self.getAttribute("images").split(',');
  let photoTitle = self.getAttribute("photo-title");
  let input = `<ul class="cards disableSelect">
`;
  for (var i in image) {
    let className = ''
    i = parseInt(i);
    if (i === 0) {
      className =  'class="current"';
    }
    let row = `<li ${className} title="${photoTitle}"><img src="/images/${image[i]}"/></li>`;
    input=`${input}${row}`
  }
  input=`${input}
      </ul>
      <button class="arrow prev"></button>
      <button class="arrow next"></button>`;
  return innerH('div', {className: 'polaroid'}, input);
}






function getDefaultTemplate(index, byteArray) {
    return h('section',
      { className: 'gallery' },
      h('div',
        {className: 'gallery__item'},
        h('input',
          { 
            className: 'gallery__selector',
            type: 'radio',
            id: 'img-'+index,
            name: 'gallery'
          },
          ''
        ),
        h('img',
          {
            className: "gallery__img", 
            src: byteArray, 
            alt: ""
          },''
        ),
        h('label',
          {
            className: "gallery__thumb", 
            htmlFor: 'img-'+index
          },
          h('img',
            {
              src: byteArray, 
              alt: ""
            },''
          )
        )
      )
    );
}

window.customElements.define('raga-photo-gallery', ImageGallery);